body {
  font-family: "Lato", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

:root {
  --bs-font-sans-serif: "Lato", sans-serif !important;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-navbar-brand-color: rgba(255, 255, 255, 0.9);
  --bs-66: #666666;
  --bs-88: #888888;
  --bs-44: #444444;
  --bs-primary-c: #282828;
}

header,
section,
footer {
  width: 100%;
  float: left;
  width: 100%;
  position: relative;
}

a.def-l-text {
  font-weight: 700;
  font-size: 18px;
  color: #029f8c;
  text-decoration: none;
}
.cancel-btn {
  color: #204092;
  font-weight: 700;
  border: 0;
  background: none;
  font-size: 18px;
  padding: 10px 15px;
}
.buy-btn {
  background: linear-gradient(94.53deg, #204092 18.01%, #047fd8 77.9%);
  border-radius: 25px;
  border: 0;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 0 30px;
  transition: all 400ms;
}

.buy-btn:hover {
  background: linear-gradient(94.53deg, #0f2868 18.01%, #075893 77.9%);
}

.error-msg {
  color: red;
  font-size: 12px;
  font-weight: 400;
}

.text-black {
  color: #000;
}
