.blits-banner {
  width: 100%;
  min-height: 600px;
  float: left;
  background: linear-gradient(
    254.97deg,
    #e7e8ff 4.15%,
    #b2b5ff 28.82%,
    #5f28b9 55.73%,
    #0c51a3 78.45%
  );
  padding-top: 160px;
  display: flex;
  align-items: center;
  padding-bottom: 80px;
}

.ba-text h1 {
  color: #fff !important;
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 44px;
  line-height: 55px;
  display: block;
  margin-bottom: 10px;
}

.ba-text p {
  font-size: 20px;
  color: #dedff3;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
}

.change-coin,
.change-coin-bg {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  margin-bottom: 10px;
  @media screen and (max-width: 575.98px) {
    min-height: 60px;
    border-radius: 10px;
  }
}

.change-coin div {
  display: flex;
  flex-direction: column;
  line-height: normal;
  @media screen and (max-width: 575.98px) {
    padding-left: 12px;
  }
}

.change-coin div strong {
  font-weight: 700;
  font-size: 24px;
  color: #282828;
  display: block;
  margin-bottom: 4px;
  align-items: center;
  display: flex;
}

.change-coin div small {
  font-weight: 500;
  font-size: 16px;
  color: #888888;
  display: block;
}

span.et-c {
  font-size: 20px;
  font-weight: 700;
  font-family: "Merriweather", serif;
  @media screen and (max-width: 575.98px) {
    position: absolute;
    right: 12px;
    font-size: 15px;
  }
}

span.et-c img {
  width: 36px;
  margin-right: 8px;
  @media screen and (max-width: 575.98px) {
   width: 27px;
  }
}

.icon-wrap {
  position: relative;
  max-width: 500px;
  width: 100%;
}

button.swap-bt {
  position: absolute;
  right: 0;
  left: 0;
  display: inline-flex;
  width: 40px;
  margin: -26px auto 0;
  height: 40px;
  background: #ffffff;
  border: 6px solid #323fad;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.change-coin-bg {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.change-coin-bg div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #b1c9f1;
}

.change-coin-bg div small {
  font-weight: 400;
  font-size: 16px;
}

.change-coin-bg strong {
  width: 100%;
  flex: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.change-coin-bg strong img {
  max-width: 100%;
  flex: none;
  margin-right: 4px;
  width: 16px;
}

.def-btn {
  background: linear-gradient(94.53deg, #34b841 18.01%, #029e8c 77.9%);
  border-radius: 30px;
  padding: 0 25px;
  border: 0;
  min-height: 50px;

  width: auto;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 400ms;
  cursor: pointer;
  margin-left: 10px;
  line-height: 2;
  display: inline-flex;
}

.ba-text .def-btn {
  float: left;
}

.def-btn:hover {
  background: linear-gradient(94.53deg, #0d7f18 18.01%, #8d9814 77.9%);
  color: #fff;
}

.ba-tocken {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
}

.ba-tocken-in {
  background: linear-gradient(101.42deg, #9c48dd 10.61%, #2128bd 99.27%);
  border-radius: 30px;
  position: relative;
  max-width: 450px;
  width: 100%;
  padding: 50px;
  margin: 8rem auto 0 auto;
  height: fit-content;
}

.ba-tocken-in img {
  max-width: 226px;
  display: block;
  margin: -170px auto 40px;
}

.ba-tocken-in h4 {
  font-size: 26px;
  font-weight: 700;
  font-family: "Merriweather", serif;
  color: #fff;
  line-height: normal;
  display: block;
  margin-bottom: 2px;
}

.ba-tocken-in p {
  color: #b7c3eb;
  font-weight: 400;
  font-size: 16px;
}

.ban-gla {
  float: left;
  width: 100%;
  margin-top: 28px;
}

.ic-t small {
  color: #e0dceb;
  display: block;
  font-size: 16px;
  display: block;
  margin-bottom: 3px;
  font-weight: 400;
}

.ic-t {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  line-height: normal;
  display: block;
}

.how-works {
  padding-top: 107px;

  background-image: url("../../images/coin-bg.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.how-works h2 {
  font-weight: 700;
  color: #1a2163;
  font-size: 60px;
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.buy-bl {
  text-align: center;
}

.buy-bl span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #15bb58;
  border: 6px solid #ffffff;
  width: 46px;
  height: 46px;
  color: #fff;
  border-radius: 100%;
  font-weight: 700;
  font-size: 18px;
  margin-top: 3px;
}

.buy-bl h4 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 20px;
  color: #1a2163;
  margin-bottom: 3px;
}

.buy-bl p {
  font-size: 16px;
  font-weight: 400;
  color: #101462;
  line-height: 19.3px;
}
.page-blts {
  background-image: url(../../images/line.svg);
  background-position: center 61px;
  background-repeat: no-repeat;
  background-size: 550px;
}

.buy-bl {
  text-align: center;
  padding: 18px 7px 33px;
}

.page-blts .row:first-child .buy-bl:nth-of-type(2) {
  position: relative;
  right: -36px;
}

.page-blts .row:nth-of-type(2) .buy-bl.left-1 {
  position: relative;
  left: -56px;
}

.blists-text p {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  color: #101462;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

img.ftr-img {
  width: 100%;
}

.tocken-part {
  background: linear-gradient(101.42deg, #d5dcf3 10.61%, #ffffff 99.27%);
  border-radius: 30px;
  padding: 50px 100px;
  max-width: 1130px;
  width: 100%;
  text-align: center;
  margin: 70px auto 0;
}

.tocken-part h3 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 50px;
  display: block;
  margin-bottom: 4px;
  line-height: normal;
}

.tocken-part p {
  max-width: 800px;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  color: #101462;
  margin-bottom: 16px;
}

.tc-q {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1a2163;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 22px;
}

.tc-q img {
  width: 22px;
  display: block;
  margin: 0 auto 10px;
}

.tc-q small {
  color: #4e536b;
  font-weight: 500;
  font-size: 14px;
}

.insider {
  clear: both;
  float: left;
  width: 100%;
  position: relative;
  z-index: 9;
}

.insider:after {
  position: absolute;
  top: -1px;
  width: 100%;
  background: linear-gradient(
    359.73deg,
    #e7e8ff 2.98%,
    #b2b5ff 37.38%,
    #0c51a3 91.03%
  );
  height: 44%;
  left: 0;
  content: "";
  z-index: -1;
}

section.ftr-bg {
  padding-bottom: 90px;
}

section.sfe-trip {
  padding-bottom: 80px;
}

section.sfe-trip .row {
  align-items: center;
}

.sf-text h3 {
  font-weight: 700;
  font-size: 30px;
  font-family: "Merriweather", serif;
  color: #1a2163;
  display: block;
  margin-bottom: 10px;
}

.sf-text p {
  font-size: 18px;
  font-weight: 400;
  color: #101462;
  line-height: 30px;
}

.sf-img img {
  max-width: 100%;
}

.sf-text {
  padding-left: 3%;
}

@media (max-width: 991.98px) {
  .ba-tocken {
    margin-top: 50px;
  }

  .how-works h2 {
    font-size: 35px;
  }

  .page-blts {
    background: none;
  }

  .page-blts .row:first-child .buy-bl:nth-of-type(2) {
    position: relative;
    right: 0px;
  }

  .page-blts .row:nth-of-type(2) .buy-bl.left-1 {
    position: relative;
    left: 0px;
  }

  .buy-bl {
    padding: 13px 37px 13px;
  }

  .tocken-part {
    padding: 60px 40px;
  }

  .tocken-part h3 {
    font-size: 35px;
  }

  .tc-q {
    font-size: 26px;
  }

  .how-works {
    background-size: 240px;
  }

  .change-coin,
  .change-coin-bg,
  .icon-wrap,
  .def-btn {
    max-width: 100%;
    margin-left: 0;
    @media (max-width: 575.98px) {
      padding: 0 15px;
      font-size: 16px;
      margin-top: 0 !important;
    }
  }

  .ba-text h1 {
    font-size: 28px;
    line-height: normal;
  }

  .ba-text p br {
    display: none;
  }

  .ba-text p {
    line-height: normal;
    margin-bottom: 15px;
  }
}

@media (max-width: 575.98px) {
  .how-works h2 {
    font-size: 28px;
  }

  .how-works {
    padding-top: 47px;
    background: none;
  }

  .tocken-part {
    padding: 51px 34px;
    width: 94%;
  }
}

.conversion_fields {
  border: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
