.pie-wrapper {
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  background: #e8505b;
  border-radius: 10px;
}
.csspie {
  position: absolute;
  width: 10px;
  height: 20px;
  overflow: hidden;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
.csspie:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 20px;
  left: -10px;
  border-radius: 10px 0 0 10px;
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
.csspie:before,
.csspie:after {
  background-color: #dfdfdf;
}
.csspie[data-value="1"]:before {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="2"]:before {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="3"]:before {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="4"]:before {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="5"]:before {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="6"]:before {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="7"]:before {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="8"]:before {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="9"]:before {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="10"]:before {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="11"]:before {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="12"]:before {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="13"]:before {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="14"]:before {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="15"]:before {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="16"]:before {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="17"]:before {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="18"]:before {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="19"]:before {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="20"]:before {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="21"]:before {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="22"]:before {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="23"]:before {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="24"]:before {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="25"]:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="26"]:before {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="27"]:before {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="28"]:before {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="29"]:before {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="30"]:before {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="31"]:before {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="32"]:before {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="33"]:before {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="34"]:before {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="35"]:before {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="36"]:before {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="37"]:before {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="38"]:before {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="39"]:before {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="40"]:before {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="41"]:before {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="42"]:before {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="43"]:before {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="44"]:before {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="45"]:before {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="46"]:before {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="47"]:before {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="48"]:before {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="49"]:before {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="50"]:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="51"]:before {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="52"]:before {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="53"]:before {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="54"]:before {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="55"]:before {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="56"]:before {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="57"]:before {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="58"]:before {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="59"]:before {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="60"]:before {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="61"]:before {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="62"]:before {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="63"]:before {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="64"]:before {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="65"]:before {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="66"]:before {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="67"]:before {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="68"]:before {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="69"]:before {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="70"]:before {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="71"]:before {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="72"]:before {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="73"]:before {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="74"]:before {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="75"]:before {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="76"]:before {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="77"]:before {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="78"]:before {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="79"]:before {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="80"]:before {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="81"]:before {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="82"]:before {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="83"]:before {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="84"]:before {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="85"]:before {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="86"]:before {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="87"]:before {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="88"]:before {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="89"]:before {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="90"]:before {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="91"]:before {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="92"]:before {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="93"]:before {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="94"]:before {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="95"]:before {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="96"]:before {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="97"]:before {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="98"]:before {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="99"]:before {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
  -webkit-transition: All 1s ease;
  transition: All 1s ease;
}
.csspie[data-value="50"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="50"]:before {
  left: 0px;
}
.csspie[data-value="51"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="51"]:before {
  left: 0px;
}
.csspie[data-value="52"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="52"]:before {
  left: 0px;
}
.csspie[data-value="53"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="53"]:before {
  left: 0px;
}
.csspie[data-value="54"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="54"]:before {
  left: 0px;
}
.csspie[data-value="55"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="55"]:before {
  left: 0px;
}
.csspie[data-value="56"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="56"]:before {
  left: 0px;
}
.csspie[data-value="57"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="57"]:before {
  left: 0px;
}
.csspie[data-value="58"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="58"]:before {
  left: 0px;
}
.csspie[data-value="59"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="59"]:before {
  left: 0px;
}
.csspie[data-value="60"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="60"]:before {
  left: 0px;
}
.csspie[data-value="61"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="61"]:before {
  left: 0px;
}
.csspie[data-value="62"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="62"]:before {
  left: 0px;
}
.csspie[data-value="63"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="63"]:before {
  left: 0px;
}
.csspie[data-value="64"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="64"]:before {
  left: 0px;
}
.csspie[data-value="65"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="65"]:before {
  left: 0px;
}
.csspie[data-value="66"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="66"]:before {
  left: 0px;
}
.csspie[data-value="67"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="67"]:before {
  left: 0px;
}
.csspie[data-value="68"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="68"]:before {
  left: 0px;
}
.csspie[data-value="69"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="69"]:before {
  left: 0px;
}
.csspie[data-value="70"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="70"]:before {
  left: 0px;
}
.csspie[data-value="71"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="71"]:before {
  left: 0px;
}
.csspie[data-value="72"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="72"]:before {
  left: 0px;
}
.csspie[data-value="73"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="73"]:before {
  left: 0px;
}
.csspie[data-value="74"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="74"]:before {
  left: 0px;
}
.csspie[data-value="75"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="75"]:before {
  left: 0px;
}
.csspie[data-value="76"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="76"]:before {
  left: 0px;
}
.csspie[data-value="77"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="77"]:before {
  left: 0px;
}
.csspie[data-value="78"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="78"]:before {
  left: 0px;
}
.csspie[data-value="79"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="79"]:before {
  left: 0px;
}
.csspie[data-value="80"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="80"]:before {
  left: 0px;
}
.csspie[data-value="81"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="81"]:before {
  left: 0px;
}
.csspie[data-value="82"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="82"]:before {
  left: 0px;
}
.csspie[data-value="83"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="83"]:before {
  left: 0px;
}
.csspie[data-value="84"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="84"]:before {
  left: 0px;
}
.csspie[data-value="85"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="85"]:before {
  left: 0px;
}
.csspie[data-value="86"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="86"]:before {
  left: 0px;
}
.csspie[data-value="87"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="87"]:before {
  left: 0px;
}
.csspie[data-value="88"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="88"]:before {
  left: 0px;
}
.csspie[data-value="89"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="89"]:before {
  left: 0px;
}
.csspie[data-value="90"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="90"]:before {
  left: 0px;
}
.csspie[data-value="91"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="91"]:before {
  left: 0px;
}
.csspie[data-value="92"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="92"]:before {
  left: 0px;
}
.csspie[data-value="93"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="93"]:before {
  left: 0px;
}
.csspie[data-value="94"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="94"]:before {
  left: 0px;
}
.csspie[data-value="95"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="95"]:before {
  left: 0px;
}
.csspie[data-value="96"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="96"]:before {
  left: 0px;
}
.csspie[data-value="97"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="97"]:before {
  left: 0px;
}
.csspie[data-value="98"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="98"]:before {
  left: 0px;
}
.csspie[data-value="99"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="99"]:before {
  left: 0px;
}
.csspie[data-value="100"] {
  width: 20px;
  height: 20px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.csspie[data-value="100"]:before {
  left: 0px;
}
.csspie[data-value="0"] {
  left: 10px;
}
.csspie[data-value="1"] {
  left: 10px;
}
.csspie[data-value="2"] {
  left: 10px;
}
.csspie[data-value="3"] {
  left: 10px;
}
.csspie[data-value="4"] {
  left: 10px;
}
.csspie[data-value="5"] {
  left: 10px;
}
.csspie[data-value="6"] {
  left: 10px;
}
.csspie[data-value="7"] {
  left: 10px;
}
.csspie[data-value="8"] {
  left: 10px;
}
.csspie[data-value="9"] {
  left: 10px;
}
.csspie[data-value="10"] {
  left: 10px;
}
.csspie[data-value="11"] {
  left: 10px;
}
.csspie[data-value="12"] {
  left: 10px;
}
.csspie[data-value="13"] {
  left: 10px;
}
.csspie[data-value="14"] {
  left: 10px;
}
.csspie[data-value="15"] {
  left: 10px;
}
.csspie[data-value="16"] {
  left: 10px;
}
.csspie[data-value="17"] {
  left: 10px;
}
.csspie[data-value="18"] {
  left: 10px;
}
.csspie[data-value="19"] {
  left: 10px;
}
.csspie[data-value="20"] {
  left: 10px;
}
.csspie[data-value="21"] {
  left: 10px;
}
.csspie[data-value="22"] {
  left: 10px;
}
.csspie[data-value="23"] {
  left: 10px;
}
.csspie[data-value="24"] {
  left: 10px;
}
.csspie[data-value="25"] {
  left: 10px;
}
.csspie[data-value="26"] {
  left: 10px;
}
.csspie[data-value="27"] {
  left: 10px;
}
.csspie[data-value="28"] {
  left: 10px;
}
.csspie[data-value="29"] {
  left: 10px;
}
.csspie[data-value="30"] {
  left: 10px;
}
.csspie[data-value="31"] {
  left: 10px;
}
.csspie[data-value="32"] {
  left: 10px;
}
.csspie[data-value="33"] {
  left: 10px;
}
.csspie[data-value="34"] {
  left: 10px;
}
.csspie[data-value="35"] {
  left: 10px;
}
.csspie[data-value="36"] {
  left: 10px;
}
.csspie[data-value="37"] {
  left: 10px;
}
.csspie[data-value="38"] {
  left: 10px;
}
.csspie[data-value="39"] {
  left: 10px;
}
.csspie[data-value="40"] {
  left: 10px;
}
.csspie[data-value="41"] {
  left: 10px;
}
.csspie[data-value="42"] {
  left: 10px;
}
.csspie[data-value="43"] {
  left: 10px;
}
.csspie[data-value="44"] {
  left: 10px;
}
.csspie[data-value="45"] {
  left: 10px;
}
.csspie[data-value="46"] {
  left: 10px;
}
.csspie[data-value="47"] {
  left: 10px;
}
.csspie[data-value="48"] {
  left: 10px;
}
.csspie[data-value="49"] {
  left: 10px;
}
@-webkit-keyframes glow {
  0% {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.726);
    box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.726);
  }
  50% {
    -webkit-box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.75);
  }
  75% {
    -webkit-box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.75);
  }
}
@keyframes glow {
  0% {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.726);
    box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.726);
  }
  50% {
    -webkit-box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.75);
  }
  75% {
    -webkit-box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.75);
  }
}
p.pie-label {
  left: 15px;
  margin-bottom: 0;
  top: -2px;
}
