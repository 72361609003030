.common-sec {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1460px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (max-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.cms-con {
  margin-bottom: 40px;
}
.sec-head {
  display: block;
  margin-bottom: 20px;
  .sec-title {
    font-family: "Merriweather", serif;
    display: block;
    margin-bottom: 0;
    line-height: 1.3;
    font-weight: 700;
    font-size: 50px;
    color: #1a2163;
    @media (max-width: 1460px) {
      font-size: 45px;
    }
    @media (max-width: 1199px) {
      font-size: 36px;
    }
    @media (max-width: 568px) {
      font-size: 30px;
    }
    &.title-sm {
      font-size: 36px;
      @media (max-width: 1460px) {
        font-size: 32px;
      }
      @media (max-width: 1199px) {
        font-size: 28px;
      }
      @media (max-width: 568px) {
        font-size: 26px;
      }
    }
  }
}
.inquiry-sec.common-sec {
  background-image: url(../../images/inquiry-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 70px;
  @media (max-width: 568px) {
    margin-top: 110px;
  }
  .inquiry-con {
    background: linear-gradient(
      352deg,
      rgba(178, 181, 255, 0.9294117647) 4.15%,
      rgba(255, 255, 255, 0.8509803922) 118.82%
    );
    padding: 32px;
    border-radius: 25px;
    @media (max-width: 568px) {
      padding: 22px;
      border-radius: 10px;
    }
    button.def-btn {
      min-width: 17vw;
    }
    .sec-head {
      p {
        font-size: 22px;
        font-weight: 500;
        @media (max-width: 568px) {
          font-size: 18px;
        }
      }
    }
    textarea {
      resize: none;
    }
  }
}
.why-choose-sec {
  .why-choose-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  .why-choose-con {
    padding-left: 50px;
    @media (max-width: 1199px) {
      padding-left: 20px;
    }
    @media (max-width: 992px) {
      padding-left: 0;
    }
    @media (max-width: 767px) {
      .cms-con {
        margin-bottom: 20px;
      }
    }
  }
}
.our-benefits-sec {
  background-color: #1847a7;
  .sec-head {
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    .sec-title {
      color: #fff;
    }
  }
  .our-benefits-box {
    position: relative;
    background-color: rgb(255 255 255);
    padding: 50px 20px;
    border-radius: 14px;
    text-align: center;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    @media (max-width: 992px) {
      padding: 30px 15px;
    }

    .our-benefits-box-img {
      img {
        width: 100px;
        margin-bottom: 30px;
        @media (max-width: 1460px) {
          width: 80px;
          margin-bottom: 20px;
        }
        @media (max-width: 568px) {
          width: 70px;
          margin-bottom: 15px;
        }
      }
    }
    .our-benefits-box-con {
      .our-benefits-box-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 1.2;
        color: #1a2163;
      }
      p {
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.clientSaySec {
  .secHead {
    margin-bottom: 40px;
  }
  .clientSaySlider {
    .swiper-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 35px;
      height: 35px;
      background: #fff;
      font-size: 20px;
      border-radius: 100%;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.14);
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 20px;
      color: #292727;
    }
    .clientSaySlide {
      border-radius: 20px;
      background-color: #ebedfe;
      padding: 44px 35px;
      > i {
        font-size: 35px;
        color: #ddeaf1;
      }
      p {
        margin-top: 15px;
        margin-bottom: 20px;
        min-height: 192px;
      }
      .clientSayQuote {
        img {
          width: 50px;
          margin-bottom: 15px;
        }
      }
      .clientSayUserName {
        font-size: 18px;
        font-weight: 600;
        color: #101462;
        display: block;
        margin-bottom: 5px;
      }
      .clientSayRating {
        img {
          max-height: 17px;
        }
      }
    }
  }
}

.faqSec .accordion-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}
.faqSec .accordion-header button {
  padding: 20px 60px 20px 30px;
  width: 100%;
  display: block;
  text-align: left;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 20px;
  background-image: url("../../images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 30px);
  background-size: 16px;
  color: #000;
  box-shadow: none !important;
  @media (max-width: 568px) {
    font-size: 18px;
    padding: 15px 60px 15px 15px;
  }
}
.faqSec .accordion-header button[aria-expanded="true"] {
  background-image: url("../../images/arrow-up.svg");
}
.faqSec .accordion-body {
  padding: 0 30px 20px;
}
.faqSec .accordion-body .cms-con {
  margin-bottom: 0;
}
