.pay-tab.nav-tabs,
.pay-tab.nav-tabs .nav-item {
  border: 0;
  background: none;
}

.pay-tab.nav-tabs .nav-item a {
  border: 0;
  font-weight: 400;
  color: #888888;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

.pay-tab.nav-tabs .nav-item a.nav-link.active {
  font-weight: 600;
  color: #029a3f;
}

.pay-tab.nav-tabs .nav-item a.nav-link.active:after {
  width: 100%;
  bottom: 0;
  height: 2px;
  background: #029a3f;
  left: 0;
  content: "";
  display: block;
  position: absolute;
}

.cur-btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.cur-btns button {
  background: #e1f3e9;
  border: 1px solid #9ed3b6;
  border-radius: 6px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-height: 60px;
  margin-right: 10px;
  padding: 0 14px;
  transition: all 400ms;
  font-weight: 400;
  font-size: 18px;
  color: #00622d;
  @media screen and (max-width: 1199.98px) {
    width: 30.333%;
    margin-bottom: 10px;
  }
}

.disabled_tab {
  border: 1px solid #c0c0c0 !important;
  background: #c0c0c0 !important;
  color: white !important;
  span {
    color: white !important;
  }
}

.cur-btn-active {
  background: #029a3f !important;
  color: #fff !important;
  span {
    color: #fff !important;
  }
}

.cur-btns button span {
  font-size: 12px;
  color: #309f63;
  transition: all 400ms;
}

p {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.cur-btns button:hover {
  background: #029a3f;
}

.cur-btns button:hover,
.cur-btns button:hover span {
  color: #fff;
}

span.inside-inp input {
  background: #f6f6f6;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 10px 50px 10px 14px;
  min-height: 50px;
  max-width: 190px;
}

span.inside-inp input:focus {
  outline: 0;
}

span.inside-inp {
  position: relative;
  margin-right: 10px;
}

span.inside-inp small {
  position: absolute;
  right: 13px;
  top: 0;
  font-weight: 600;
  font-size: 14px;
  color: #444444;
}

.enter-man {
  font-size: 16px;
  color: #888888;
  font-weight: 600;
}

.ftr-buy {
  margin-top: 28px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
}
