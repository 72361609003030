.blog-sec {
  &.common-sec {
    padding-top: 150px;
  }
  .sec-head {
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .blog-box {
    background-color: #fff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    overflow: hidden;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    transition: all 0.4s ease-in-out;
    @media (max-width: 767px) {
      height: unset;
      margin-bottom: 20px;
    }
    &:hover {
      box-shadow: 0px 2px 14px rgba(19, 90, 195, 0.5);
      transform: translateY(5px);
      a.more-det {
        color: #fff;
      }
      a.more-det:after {
        opacity: 1;
        background: #109062;
      }
    }
    .blog-box-img {
      position: relative;
      img {
        width: 100%;
      }
    }
    .blog-box-date {
      background: #69b670;
      position: absolute;
      bottom: -15px;
      left: 20px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
      line-height: 1;
      padding: 10px 12px;
      text-align: center;
    }
    .blog-box-con {
      padding: 20px;
      .blog-box-title {
        min-height: 52px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 10px;
        @media (max-width: 568px) {
          min-height: unset;
        }
      }
      .blog-box-title a {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.2;
        color: #282828;
        font-family: "Merriweather", serif;
        display: block;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
      .more-det {
        text-decoration: none;
        &:hover {
          color: #fff;
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }

  .blog-box-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .blog-box-tag {
      display: block;
      padding: 5px 7px;
      border-radius: 5px;
      background-color: #282828;
      color: #fff;
      text-transform: capitalize;
      font-size: 14px;
      & + .blog-box-tag {
        margin-left: 10px;
      }
    }
  }

  .blog-details-title {
    display: block;
    font-size: 34px;
    font-weight: 700;
    color: #1a2163;
    margin-bottom: 10px;
    @media (max-width: 1200px) {
      font-size: 28px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
    }
    @media (max-width: 568px) {
      font-size: 22px;
    }
  }
  .blog-details-date {
    color: #282828;
    margin-bottom: 0;
  }
  .blog-details-img {
    img {
      width: 100%;
      border-radius: 6px;
      margin-top: 30px;
      margin-bottom: 30px;
      display: block;
      @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
