.listproperty_wrap {
  padding-top: 20px;
  padding-bottom: 50px;
}

.listproperty_left h4 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  color: #282828;
  font-size: 20px;
  display: block;
  margin-bottom: 15px;
}

.listproperty_left_label label {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  @media (max-width: 991.98px) {
    margin-bottom: 6px;
  }
}

.listproperty_left_inputh input,
.listproperty_left_input select,
.listproperty_left_input textarea {
  background: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 6px;
  min-height: 40px;
  color: #282828;
  width: 90%;
  padding: 10px 15px;
  font-weight: 700;
  @media (max-width: 991.98px) {
    width: 100%;
  }
}

.listproperty_left_input input,
.listproperty_left_input select,
.listproperty_left_input textarea {
  background: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 6px;
  min-height: 50px;
  color: #282828;
  width: 90%;
  padding: 10px 15px;
  font-weight: 700;
  @media (max-width: 991.98px) {
    width: 100%;
  }
}
.listproperty_disabled input {
  background: #f5f5f5 !important;
}

.listproperty_left_input input:focus {
  outline: 0;
  border-color: #15bb58;
  color: #15bb58;
}

.listproperty_left_label {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 991.98px) {
    margin-bottom: 0px !important;
  }
  span {
    color: red;
  }
}

.listproperty_left {
  padding-right: 5%;
  @media (max-width: 991.98px) {
    padding-right: 10px;
  }
}

.listproperty_left_input input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c0c0c0;
  font-weight: 400;
  opacity: 1;
  /* Firefox */
}

.listproperty_left_input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c0c0;
  font-weight: 400;
}

.listproperty_left_input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c0c0c0;
  font-weight: 400;
}

.listproperty_left_input textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c0c0c0;
  font-weight: 400;
  opacity: 1;
  /* Firefox */
}

.listproperty_left_input textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c0c0;
  font-weight: 400;
}

.listproperty_left_input textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c0c0c0;
  font-weight: 400;
}

.listproperty_left_input .form-check-inline .form-check-input[type="radio"] {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  flex: none;
  min-height: auto;
  border: 3px solid #cccccc;
}

label.form-check-label {
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  margin-left: 10px;
}

.form-check-inline {
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
}

.form-check.form-check-inline:first-child {
  padding-right: 5%;
}

.listproperty_left_input select {
  background: url("") #fff right 6px center no-repeat;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071Z' fill='%23bbbbbb' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position-x: 98%;
  padding-right: 21px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

section .listproperty_left_input textarea:focus {
  outline: 0;
}

section .listproperty_left_input textarea {
  resize: vertical;
  min-height: 158px;
  color: #282828;
}

.listproperty__media p {
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  margin-bottom: 15px;
  display: block;
}

.listproperty__media ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
}

.listproperty__media ul li {
  width: 100%;
  min-height: 158px;
  margin-right: 12px;
  margin-bottom: 15px;
  flex: none;
  display: flex;
  max-width: 228px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.btn-file {
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #066b2f;
  width: 100%;
  height: 100%;
  background: #ebf9f0;
  border: 2px solid #d0e9da;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.btn img {
  margin-bottom: 10px;
  max-width: 100%;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.media_delete {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #666666;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100%;
  z-index: 9;
}

.media_delete span {
  position: absolute;
  min-width: 150px;
  right: 40px;
  color: #fff;
  text-shadow: 0 0 7px #000;
}

.media_delete:hover {
  background: #ee4a4a;
}

/* button.btn-file:after {
    content: "";
    background: rgb(0 0 0 / 26%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
} */

p.note {
  font-weight: 500;
  color: #444444;
  font-size: 14px;
  display: block;
  width: 100%;
}

.sub-btns {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 991.98px) {
    button.def-btn {
      margin-left: 20px;
    }
  }
}

.listproperty_right_in {
  width: 100%;
  text-align: center;
  background: linear-gradient(
    152.58deg,
    #d8fee3 7.07%,
    #ffffff 52.22%,
    #c6f5ff 94.15%
  );
  border: 6px solid #ffffff;
  border-radius: 11px;
  padding: 35px 36px;
  box-shadow: 0 0 2px 6px #d7efde;
}

.listproperty_right_in span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  color: #fff;
  background: linear-gradient(131.02deg, #15bb58 17.51%, #0e9484 90.91%);
  font-weight: 700;
  font-size: 32px;
}

.listproperty_right_in h3 {
  font-size: 20px;
  color: #056531;
  margin-bottom: 12px;
}

.listproperty_right_in p {
  font-size: 16px;
  color: #444444;
  font-weight: 400;
}

.__video button.btn-file {
  background: #ebeef9;
  border: 2px solid #d0e1e9;
  border-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.listproperty_right {
  margin-top: 3%;
  @media (max-width: 991.98px) {
    margin-top: 5%;
  }
}

.listproperty_left_label_secondpage label {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: #666666;

  span {
    color: red;
  }
}

span.inp-wrap {
  position: relative;
  width: 100%;
}

input:focus {
  outline: 0;
}

span.inp-wrap input,
.listproperty_left_label_secondpage input {
  background: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 6px;
  min-height: 50px;
  width: 100%;
  padding: 10px 19px;
}

section span.inp-wrap select {
  border: 2px solid #cccccc;
  border-radius: 6px;
  min-height: 50px;
  width: 100%;
  background: url("") #fff right 6px center no-repeat;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071Z' fill='%23bbbbbb' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position-x: 98%;
  padding-right: 31px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

span.inp-wrap small {
  position: absolute;
  top: -4px;
  right: 30px;
  font-weight: 700;
  font-size: 18px;
  color: #444444;
}

.listproperty__amneties {
  display: flex;
  flex-wrap: wrap;
  column-count: 2;
}

.listproperty__amneties .form-check {
  width: 50%;
  margin-bottom: 20px;
}

.listproperty__amneties .form-check-input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #cbcbcb;
  border-radius: 4px;
}

.listproperty__amneties .form-check label.form-check-label {
  font-weight: 400;
  font-size: 17px;
  color: #282828;
  margin-left: 10px;
}

.add_property input {
  margin-bottom: 10px;
}

button.add-btn {
  font-weight: 400;
  font-size: 16px;
  color: #135ac3;
  border: 0;
  background: none;
}

.g-map iframe {
  width: 100%;
}

.g-map {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 6px;
  overflow: hidden;
  max-width: 605px;
}

.g-map img {
  width: 100%;
}

button.back-btn {
  background: #ffffff;
  border: 3px solid #15bb58;
  border-radius: 30px;
  padding: 0 25px;
  min-height: 52px;
  font-weight: 700;
  font-size: 18px;
  color: #0bae4c;
}

.listproperty_left {
  .listproperty_left_input {
    p {
      color: #444444;
      font-weight: 400;
    }

    .blits-name {
      span {
        font-weight: 500;
        font-size: 20px;
        color: #444444;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.doc_upload {
  h4 {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    display: block;
    margin-bottom: 10px;
  }

  .file-input {
    display: inline-flex;
    width: auto;
    max-width: 140px;
    width: 100%;
  }

  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #009f49;
    font-size: 18px;
    padding: 10px 13px;
    width: 100%;
    background: #ebf9f0;
    border: 2px solid #d0e9da;
    border-radius: 6px;
  }

  span {
    padding-left: 4px;
  }

  .doc_type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 10px;
    font-size: 12px;
    justify-content: space-between;

    .doc_type_in {
      display: inline-flex;
      font-size: 12px;
      color: #fff;
      width: auto;
      padding: 4px 4px;
      line-height: 12px;

      &.__pdf {
        background: #d06868;
        border-radius: 2px;
      }

      &.__DOC {
        background: #2f73da;
        border-radius: 2px;
      }
    }
  }
}
