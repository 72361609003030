.serch-form {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 575.98px) {
    flex-direction: column;
  }
}

.form-control-sr {
  position: relative;
  max-width: 416px;
  width: 100%;
  margin-right: 25px;
}

.form-control-sr input {
  background: #f6f6f6;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  width: 100%;
  padding: 0 21px;
  min-height: 44px;
}

.form-control-sr input:focus {
  outline: 0;
}

.form-control-sr button {
  border: 0;
  background: none;
  position: absolute;

  right: 15px;
  top: 5px;
}

.listing-p-tile h1 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 24px;
  color: #1a2163;
  margin-bottom: 4px;
}

.serch-form ul {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid #ddd;
}

.serch-form ul li {
  display: inline-block;
}

.serch-form ul li a {
  color: #888888;
  font-weight: 400;
  font-size: 16px;
  margin-left: 15px;
  display: inline-block;
  text-decoration: none;
}

.serch-form ul li a:hover {
  color: #029a3f;
}

span.sub {
  font-size: 16px;
}

.listing-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
  @media screen and (max-width: 767.98px) {
    padding-top: 30px;
  }
}

.single-l-in {
  background: #f6f6f6;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.14);
  border-radius: 30px;
  float: left;
  width: 100%;
  padding: 17px 17px 28px;
  text-decoration: none;
  transition: all 400ms;
  cursor: pointer;
}

.single-l-in:hover {
  box-shadow: 0px 2px 14px rgba(19, 90, 195, 50%);
}

.sin-img {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(195, 200, 212);
  padding-top: 66.66%;
  overflow: hidden;
  position: relative;
  border: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  min-height: 272px;
}

.sin-img img {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px 0px 0px 50%;
}

.single-l-in h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #282828;
  font-family: "Merriweather", serif;
  display: block;
  margin-bottom: 2px;
  margin-top: 12px;
}

span.loct {
  color: #444444;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: normal;
  width: 100%;
}

span.loct img {
  margin-right: 8px;
  max-width: 100%;
}

.loct-d {
  font-weight: 800;
  font-size: 20px;
  color: #008937;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.loct-d img {
  max-width: 22px;
  margin-right: 10px;
  flex: none;
}

.sub-status,
.ownr-name {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.sub-status {
  p.pie-label {
    width: 200px;
    left: 15px;
    top: -2px;
  }
}

.sub-status small,
span.dial small,
.ownr-name small {
  font-size: 12px;
  color: #444444;
  display: block;
  margin-bottom: 4px;
}

.pie-red,
span.dial {
  position: relative;
  width: 100%;
  float: left;
}

p.pie-label {
  width: 170px;
  position: absolute;
  top: 0;
  font-weight: 500;
  color: #e8505b;
  text-align: center;
  font-size: 16px;
  line-height: normal;
}

span.dial p {
  margin-bottom: 0;
  color: #444444;
  font-weight: 600;
  font-size: 14px;
}

span.dial {
  margin-bottom: 14px;
}

.own-stat {
  display: inline-flex;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: #ba3838;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  margin-right: 7px;
}

span.ow-nme {
  font-weight: 700;
  color: #444444;
  font-size: 16px;
}

.more-det {
  background: linear-gradient(99.02deg, #69b670 15.66%, #0c786b 78.72%);
  border-radius: 30px;
  min-height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 23px;
  font-weight: 700;
  font-size: 18px;
  color: #029f8c;
  position: relative;
  z-index: 9;
  transition: all 400ms;
  border: 0;
}

.more-det:after {
  content: "";
  background: #f5f5f5;
  position: absolute;
  left: 4px;
  right: 4px;
  margin: 0 auto;
  top: 4px;
  bottom: 4px;
  border-radius: 30px;
  z-index: -1;
  transition: all 400ms;
}

.single-l-in:hover span.more-det:after {
  opacity: 1;
  background: #109062;
}

.single-l-in:hover span.more-det {
  color: #fff;
}

span.d-name {
  position: absolute;
  z-index: 9;
  top: 14px;
  right: 14px;
  background: #15bb58;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  padding: 2px 9px;
  line-height: normal;
}

span.r-name {
  position: absolute;
  z-index: 9;
  top: 14px;
  right: 14px;
  background: #e8505b;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  padding: 2px 9px;
  line-height: normal;
}

span.post-dte-sng {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 13px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

span.post-dte-sng small {
  display: block;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}

span.post-dte-sng {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  font-family: "Merriweather", serif;
}

.slider_cover {
  width: 180px;
  border: 1px solid #c0c0c0;
  height: 35px;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px 14px;
  color: #666666;
  font-weight: 400;
  font-size: 15px;
  @media (max-width: 575.98px) {
  width: 100%;
  }
}

.inner_slider_cover {
  justify-content: center;
  z-index: 99999;
  position: absolute;
  padding: 1px 10px;
  background: white;
  border-radius: 10px;
  width: 320px;
  height: 100px;
  box-shadow: 0px 0px 1rem #c0c0c0;
  @media screen and (max-width: 767.98px) {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.h-85 {
  height: 85vh;
  @media screen and (max-width: 575.98px) {
    height: 50vh;
  }
}

.object-fit-cover {
  object-fit: cover;
}

.cursorPointer {
  cursor: pointer;
}

.quick-filters li {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;

  .svg_selected {
    path {
      stroke: #029a3f;
    }
  }

  ._selected {
    color: #029a3f;
    font-weight: 600;
  }
}

.text-grey {
  color: #c0c0c0;
}

.green_link {
  padding-top: 0.5rem;
  font-size: 22px;
  color: #15bb58;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.loading_overlay {
  width: 100%;
  height: 200px;
  position: absolute;
  top: -12rem;
  z-index: 99;
  background: #dbdbdb66;
  border-radius: 8px;
  margin: 10px 0px;

  h1 {
    text-align: center;
    padding-top: 5rem;
  }
}

.listing-page {
  .listing-p-tile {
    @media screen and (max-width: 767.98px) {
      padding-bottom: 15px;
    }
  }
}
