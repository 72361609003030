section.reg-wrap {
  min-height: 100vh;
  position: relative;
  top: 0;
  width: 100%;
  padding-top: 180px;
  position: relative;
  z-index: 9;
  padding-bottom: 90px;
}

section.reg-wrap:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  content: "";
  z-index: -1;
  background-image: url("data:image/svg+xml,%3Csvg width='1440' height='598' viewBox='0 0 1440 598' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1440 0H0V50.8659V124V598C28.6548 586.605 53.68 562.569 74.5025 539.601C105.641 505.416 144.038 467.493 191.987 470.875C218.923 472.745 241.703 487.478 264.484 502.212C287.265 516.945 310.045 531.678 336.981 533.548C368.732 535.763 396.982 519.68 425.192 503.621C453.671 487.408 482.11 471.219 514.067 473.902C534.508 475.683 553.229 484.941 570.613 495.09C585.275 503.559 599.596 512.681 613.911 521.799C648.651 543.926 683.363 566.036 722.865 578.771C778.647 596.754 849.902 590.522 884.479 545.833C887.564 541.859 890.353 537.624 893.136 533.399C899.829 523.236 906.485 513.129 917.145 506.841C927.27 500.965 939.496 499.363 951.531 498.829C982.237 497.549 1013.52 502.178 1044.56 506.773C1095.61 514.327 1146.02 521.787 1192.23 502.746C1210.75 495.135 1227.48 483.509 1244.1 471.961C1269.46 454.332 1294.56 436.888 1325.38 434.198C1367.98 430.459 1406.19 456.632 1439.81 481.202L1440 481.38V124V11.874V0Z' fill='url(%23paint0_linear_12101_3342)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_12101_3342' x1='1405.5' y1='63.9883' x2='353.059' y2='744.435' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E7E8FF'/%3E%3Cstop offset='0.301928' stop-color='%23B2B5FF'/%3E%3Cstop offset='0.631262' stop-color='%235F28B9'/%3E%3Cstop offset='0.909375' stop-color='%230C51A3'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.reg-in {
  width: 100%;
  float: none;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 56px 64px;
  max-width: 570px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 575.98px) {
    padding: 36px 24px;
  }
}

.forgot-reg-in {
  width: 100%;
  float: none;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 56px 64px;
  max-width: 570px;
  margin: 0 auto;
}

.terms-reg-in {
  width: 100%;
  float: none;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 56px 64px;
  max-width: 1200px;
  margin: 0 auto;
}
// notfound block

.notfound-block {
  width: 100%;
  float: none;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  padding: 56px 64px;
  max-width: 570px;
  margin: auto;
  text-align: center;
}

.notfound-icon-img img {
  margin-bottom: 30px;
  max-width: 100px;
  @media (max-width: 992px) {
    max-width: 80px;    
  }
  @media (max-width: 767px) {
    max-width: 70px;
  }
}

h1 {
  font-weight: 700;
  font-size: 30px;
  font-family: "Merriweather", serif;
  color: #282828;
  line-height: normal;
  margin-bottom: 5px;
  display: block;
}

.reg-in p {
  color: #444444;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.g-btn {
  border: 0;
  background: #135ac3;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  transition: all 400ms;
  margin-bottom: 35px;
}

.g-btn:hover {
  background: #0b4398;
}

.g-btn svg {
  width: 18x;
  margin-right: 5px;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  font-family: "Merriweather", serif;
  color: #282828;
  margin-top: 9px;
  margin-bottom: 16px;
}

h3 a {
  display: inline-block;
  color: #15bb58;
  font-weight: 700;
  font-size: 18px;
  font-family: "Merriweather", serif;
  margin-left: 8px;
  cursor: pointer;
  text-decoration: none;
}

label {
  font-size: 16px;
  font-weight: 500;
  color: #282828;
  display: block;
  line-height: normal;
  margin-bottom: 3px;
}

.form-control {
  appearance: none;
}
.form-control,
.form-select {
  outline: none;
  border-style: none;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 35px;
  padding: 5px 12px !important;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
      0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }
}

.search_form {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  min-height: 38px;

  appearance: none;
  outline: none;
  border-style: none;
  background: #ffffff;
  border: 0px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 35px;
  padding: 5px 40px !important;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
      0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }
}

p.terms {
  font-weight: 400;
  color: #444444;
  font-size: 16px;
}

p.terms span {
  display: inline-block;
  text-decoration: underline;
  font-weight: 500;
  color: #282828;
  cursor: pointer;
}

button.def-btn {
  width: auto;
  float: right;
  min-height: 52px;
}

.for-login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.for-forgot {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

a.pl-text-btn {
  border: 0;
  background: none;
  color: #ca2d16;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
}

.form-check.form-check-inline label {
  margin-bottom: 0;
}

.form-check-input:checked {
  background-color: #15bb58;
  border-color: #15bb58;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  top: -2px;
  position: relative;
}

// button.forgot-def-btn {
//     width: auto;
//     float: right;
//     margin-left: auto;
//     min-height: 52px;
// }

// .forgot-reg-in {
//     display: block;
//     width: 100%;
//     float: none;
//     background: #FFFFFF;
//     box-shadow: 0px 4px 14px rgba(0, 0, 0, 16%);
//     border-radius: 30px;
//     padding: 56px 64px;
//     max-width: 570px;
//     margin: 0 auto;
// }

// for view password
.eye-icon {
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    width: 25px;
    right: 12px;
    top: 8px;
  }
}
.urn-pay-screen {
  button.btn.btn-dark.w-100 {
    background: #15bb58;
    border-radius: 6px;
    border: 0;
    min-height: 42px;
    margin-bottom: 10px;
  }
  form label {
    font-size: 12px;
    font-weight: 500;

    width: 100%;
    color: rgba(26, 26, 26, 0.7) !important;
  }
  span.text-muted {
    color: #282828 !important;
    font-weight: 600;
    font-size: 26px;
  }
}

div#urn-ftr {
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  text-align: center;
  padding: 0 25px;
  display: block;
  line-height: normal;
}
