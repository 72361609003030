.listprop_header_wrap {
  padding-top: 140px;
  padding-bottom: 10px;
  @media (max-width: 991.98px) {
    padding-top: 120px;
  }
}

.listprop_header_title {
  @media (max-width: 991.98px) {
    margin-bottom: 30px;
  }
  h5 {
    font-family: "Merriweather", serif;
    font-weight: 700;
    color: #282828;
  }
}

.listprop_header_nav {
  text-align: center;
  display: flex;
  justify-content: center;
}

.listprop_header_nav .nav .nav-item .nav-link {
  position: relative;
  padding: 21px 30px 21px;
  font-size: 16px;
  font-weight: 500;
  color: #888888;
}

.listprop_header_nav .nav .nav-item .nav-link:after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2.37427L6 10.3399L2 6.35724' stroke='%23BBBBBB' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  width: 30px;
  height: 30px;
  background-color: #eaeaea;
  border-radius: 100%;
  background-size: 10px;
  position: absolute;
  top: -13px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  border: 5px solid transparent;
}

.successtext {
  color: #029a3f !important;
  font-weight: 700;
}

.listprop_header_nav .nav .nav-item .nav-link-completed:after {
  background-color: #029a3f;
  color: #029a3f;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.listprop_header_nav .nav .nav-item .nav-link.active:after {
  border: 5px solid #029a3f;
  background: #fff;
}

.listprop_header_nav .nav .nav-item .nav-link.active {
  color: #029a3f;
  font-weight: 700;
}

.listprop_header_nav .nav {
  position: relative;
  z-index: 9;
  @media (max-width: 767.98px) {
    position: relative;
    z-index: 9;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-top: 21px;
    .nav-item {
      flex: 0 0 auto;
    }
  }
}

.listprop_header_nav .nav:after {
  position: absolute;
  top: 0;
  width: 82%;
  background: #f6f6f6;
  height: 6px;
  content: "";
  z-index: -1;
  border-radius: 30px;
  right: 0;
  left: 0;
  margin: 0 auto;
  @media (max-width: 767.98px) {
    top: auto;
    width: 100%;
  }
}

.listprop_header_small {
  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #666666;
    font-weight: 700;
    font-family: "Merriweather", serif;
  }
  @media (max-width: 1199.98px) {
    display: none;
  }
}
