section.profile_header {
  margin-top: 80px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  .profile_head_in {
    display: flex;
    align-items: center;
    @media (max-width: 991.98px) {
      flex-wrap: wrap;
      margin-top: 40px;
    }
    h2 {
      margin-bottom: 0;
      font-size: 24px;
    }

    .nav {
      padding-left: 25px;
      @media (max-width: 991.98px) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        padding-left: 0;
      }
      .nav-item {
        @media (max-width: 991.98px) {
          flex: 0 0 auto;
        }
        .nav-link {
          padding: 31px 15px;
          font-weight: 500;
          font-size: 18px;
          color: #888888;
          position: relative;

          &:hover {
            color: #029a3f;
          }

          &.active {
            color: #029a3f;
            font-weight: 600;
          }

          &.active:after {
            width: 100%;
            position: absolute;
            content: "";
            background: #15bb58;
            height: 3px;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}

.profle_content {
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 79vh;
  .profle_left_sect {
    flex: 0 0 auto;
    width: 36.666667%;
    @media (max-width: 1199.98px) {
      width: 51.666667%;
    }
    @media (max-width: 991.98px) {
      width: 100%;
    }
    h3 {
      font-size: 20px;
      margin-top: 0;
      justify-content: space-between;
      display: flex;
      align-self: center;

      span {
        font-size: 18px;
        color: #15bb58;
        cursor: pointer;
        font-family: "Lato", sans-serif;
      }
    }

    .property_invested {
      background: #f6f6f6;
      border: 1px solid #eaeaea;
      border-radius: 20px;
      padding: 23px 24px;
      margin-bottom: 12px;
      display: flex;
      transition: all 400ms;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0 2px #00a040;
      }

      .property_inv_img {
        display: flex;
        flex-direction: column;
        width: 90px;
        text-align: center;

        .property_inv_img_in {
          width: 90px;
          height: 90px;
          border-radius: 12px;
          background-color: #ddd;
          overflow: hidden;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;

          img {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0px 0px 0px 50%;
          }
        }

        span {
          display: block;
          margin-top: 10px;
          font-weight: 500;
          font-size: 16px;
          color: #282828;
          line-height: normal;
        }
      }

      .property_inv_details {
        padding-left: 24px;
        line-height: normal;

        h4 {
          font-family: "Merriweather", serif;
          font-weight: 700;
          font-size: 20px;
          line-height: normal;
          display: block;
          margin-bottom: 4px;
        }

        small {
          display: block;
          color: #666;
          font-size: 16px;
          margin-bottom: 12px;
        }

        span.bl_amount {
          display: flex;
          width: 100%;
          font-weight: 600;
          font-size: 18px;
          color: #282828;
          margin-bottom: 11px;
          align-items: center;
          @media (max-width: 767.98px) {
            flex-wrap: wrap;
          }
          img {
            width: 20px;
            flex: none;
            margin-right: 6px;
          }
        }

        span.bl_sh_budget {
          font-weight: 500;
          font-size: 16px;
          color: #444444;
          display: block;

          strong {
            color: #282828;
            font-weight: 500;
          }
        }
      }
    }
    h5{
      font-size: 13px;
    display: block;
    margin-top: 10px;
    color: #888;
    text-align: center;
    }
  }

  .profile_right_side {
    flex: 0 0 auto;
    width: 62.666667%;
    padding-left: 40px;
    @media (max-width: 1199.98px) {
      width: 47.666667%;
    }
    @media (max-width: 991.98px) {
      width: 100%;
      padding-left: 10px;
      margin-top: 25px;
    }
    h1 {
      font-size: 24px;
      line-height: normal;
      margin-bottom: 2px;
    }

    .proprty_title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 991.98px) {
        align-items: center;
      }
      .proprty_title_in {
        h1 {
          font-size: 24px;
          line-height: normal;
          margin-bottom: 2px;

          span {
            background: #282828;
            border-radius: 4px;
            color: #dedede;
            font-family: "Lato", sans-serif;
            font-weight: 600;
            font-size: 18px;
            padding: 4px 7px;
            margin-left: 14px;
          }
        }

        small {
          color: #666666;
          font-size: 16px;
        }
        @media (max-width: 1199.98px) {
          order: 2;
          margin-top: 15px;
        }
      }
      @media (max-width: 1199.98px) {
        button.def-btn {
          margin-left: 0;
        }
      }
    }

    .property_price {
      display: block;
      font-weight: 600;
      font-size: 20px;
      color: #888888;
      margin-top: 16px;
      margin-bottom: 20px;

      strong {
        font-weight: 800;
        font-size: 20px;
        color: #00a040;
        margin-right: 10px;
      }
    }

    .proprty_inv_details {
      .b-dets {
        margin-top: -38px;
      }

      .property_inv_img_in {
        width: 130px;
        height: 130px;
        border-radius: 10px;
        background-color: #ddd;
        overflow: hidden;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        img {
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          transform: translateX(-50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin: 0px 0px 0px 50%;
        }

        span.gallery_number {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 6px;
          position: absolute;
          color: #fff;
          bottom: 12px;
          padding: 5px 8px;
          line-height: normal;
          cursor: pointer;
        }
      }
    }

    .invested_date {
      font-weight: 600;
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-top: -14px;

      img {
        width: 20px;
        margin-right: 10px;
      }
    }

    .holding_details {
      .ab-amnty {
        background: #dff3e5;
        border-radius: 16px;
        float: left;
        width: 100%;
        padding: 36px;

        table {
          th {
            td {
              h2 {
                margin-bottom: 4px;
              }
            }
          }

          tr {
            &.cur_value {
              td {
                font-weight: 700;
                color: #282828;
              }
            }
          }

          tr {
            td {
              color: #666;
              padding: 8px 0;

              &:last-child {
                font-weight: 600;
                color: #444444;
              }

              &.ch_value {
                color: #038939;
                font-weight: 700;
              }

              &.ch_sold {
                color: #de331a;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .det-left {
      float: left;
      width: 100%;
      clear: both;
    }

    .ab-invest.ow-adrs {
      table tr td:last-child {
        color: #444444;
      }
    }

    .view_listing {
      display: block;
      float: left;
      width: 100%;
      margin-top: 30px;

      a {
        color: #135ac3;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
      }
    }

    .property_filt {
      margin-top: 8px;
      float: left;
      width: 100%;
      margin-bottom: 20px;

      .drop_wrap {
        .dropdown {
          .btn.btn-success {
            font-weight: 400;
            font-size: 18px;
            color: #282828;
            background: none;
            border: 0;
            padding-left: 0;
            position: relative;

            &:focus {
              border: 0;
            }

            &:after {
              border: 0;
              width: 12px;
              height: 18px;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              top: 11px;
              background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2L6 6L2 2' stroke='%23888888' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
          }

          .dropdown-menu.show {
            background: #ffffff;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.138);
            border-radius: 16px;
            border: 0;
            padding: 26px 20px;

            a {
              &:hover {
                background: none;
                color: #282828;
                font-weight: 500;
              }
            }
          }
        }
      }

      .filter_search {
        form {
          position: relative;

          .form-control {
            min-height: 44px;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 6px;
            padding-left: 45px;
            font-size: 18px;
            color: #666;

            &:focus {
              background: none;
              outline: 0;
              box-shadow: none;
            }
          }

          button.btn.btn-outline-success {
            position: absolute;
            left: 5px;
            background: none;
            border: 0;
            top: 7px;

            &:focus {
              background: none;
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }
    }

    .transact_listing {
      float: left;
      width: 100%;
      clear: both;
      border-radius: 10px;
      padding: 15px 17px;
      margin-bottom: 4px;

      &:nth-of-type(odd) {
        background: #f6f6f6;
      }

      .price_manage {
        display: flex;
        line-height: normal;

        .price_icon {
          display: inline-flex;
          flex: none;
          align-items: center;
          justify-content: center;
          background: #dedede;
          width: 44px;
          height: 44px;
          border-radius: 100%;
          margin-right: 10px;
        }

        .property_time_det {
          display: flex;
          flex-direction: column;

          strong {
            font-weight: 700;
            font-size: 18px;
            color: #282828;
            margin-bottom: 2px;
          }

          span {
            font-size: 16px;
            color: #666666;
          }
        }
      }

      .ref_number {
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }

      .property_prices_det {
        display: flex;
        flex-direction: column;
        text-align: right;
        line-height: normal;

        strong {
          font-weight: 700;
          font-size: 18px;
          color: #282828;
          margin-bottom: 2px;
        }

        span {
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }
}

.property_invested_balance {
  background: #fdfdfd;
  border: 2px solid #15bb58;
  border-radius: 20px;
  text-align: center;
  padding: 30px;

  img {
    max-width: 45px;
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  h6 {
    font-weight: 700;
    font-family: "Merriweather", serif;
    font-size: 18px;
    color: #444;
    display: block;
    line-height: normal;
    margin-top: 4px;
    margin-bottom: 10px;
  }

  strong {
    font-weight: 700;
    font-size: 44px;
    display: block;
    line-height: normal;
  }

  small {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 5px;
  }

  span.rt_change {
    font-weight: 700;
    font-size: 16px;
    color: #029c3f;
    display: block;
    margin-bottom: 20px;
  }

  .more-det {
    min-height: 51px;
  }
}

section.profle_content.my_listing {
  .profle_left_sect {
    .under_varification,
    .approved_varification,
    .rejected_varification {
      position: relative;

      .bl_amount {
        color: #05903d !important;

        span._varify {
          background: #468ab7;
          border-radius: 2px;
          font-size: 14px;
          color: #fff;
          padding: 3px 9px;
          margin-left: 6px;
        }
      }

      .property_inv_img {
        span {
          font-weight: 700;
          font-size: 16px;
          color: #282828;
        }
      }

      .varify-icon {
        display: inline-flex;
        width: 26px;
        height: 26px;
        align-items: center;
        justify-content: center;
        background: #468ab7;
        position: absolute;
        right: 12px;
        top: 15px;
        border-radius: 100%;
      }
    }

    .approved_varification {
      span.bl_amount {
        span._varify {
          background: #15bb58;
        }
      }

      .varify-icon {
        background: #15bb58;
      }
    }

    .rejected_varification {
      span.bl_amount {
        color: #d42b13 !important;

        span._varify {
          background: #d42b13;
        }
      }

      .varify-icon {
        background: #d42b13;
      }
    }
  }

  .profile_right_side {
    .listing_on {
      font-weight: 600;
      font-size: 20px;
      color: #282828;

      small {
        display: block;
        color: #666666;
        font-size: 16px;
        font-weight: 400;
      }

      img {
        max-width: 100%;
        margin-right: 4px;
      }

      span {
        background: #e7a705;
        border-radius: 6px;
        font-weight: 600;
        font-size: 18px;
        padding: 4px 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        color: #fff;
        line-height: normal;
      }

      &:last-child {
        span {
          &:last-child {
            background: #ff725e;
          }
        }
      }
    }

    .ab-amnty {
      background: #f3fbfc;
      border-radius: 16px;

      table {
        tr {
          td {
            img {
              width: 20px;
              vertical-align: sub;
              margin-right: 10px;
            }

            span {
              margin-left: 3%;
            }
          }

          &.cur_value {
            td {
              &:first-child {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

.ab-invest.media_listing {
  .property_inv_img_in {
    width: 148px;
    height: 96px;
    border-radius: 10px;
    background-color: #ddd;
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    margin-bottom: 12px;

    img {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0px 0px 0px 50%;
    }
  }
}

.profile_details {
  text-align: center;
  background: #f9f9f9;
  border-radius: 20px;
  float: left;
  width: 100%;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 25px;

  .profle_img {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c3c8d4;
    overflow: hidden;
    width: 200px;
    height: 200px;
    background: #fff;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    vertical-align: text-top;
    position: relative;
    margin-bottom: 17px;
    .replacer {
      cursor: pointer;
    }
    .replacer:hover .image {
      transition: 0.3s ease-in-out;
      opacity: 0.3;
    }

    .replacer:hover .middle {
      opacity: 1;
    }
    img {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0px 0px 0px 50%;
    }
    .imageUploadData {
      display: none;
      position: relative;
      z-index: 4;
    }
    .hover-heading {
      color: black;
      font-weight: 700;
      font-size: 20px;
    }
  }

  small {
    font-weight: 600;
    font-size: 18px;
    color: #666666;
    display: block;
    margin-bottom: 30px;
  }

  .born_date {
    background: #666666;
    border-radius: 10px;
    padding: 15px 25px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: auto;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
}

.profile_description {
  @media (max-width: 767.98px) {
    margin-top: 25px;
  }
  small {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #888888;
    width: 100%;
    line-height: normal;
    margin-bottom: 2px;
  }

  strong {
    font-weight: 600;
    font-size: 18px;
    color: #282828;
    display: block;
    margin-bottom: 36px;
    @media (max-width: 767.98px) {
      word-break: break-all;
    }
  }

  h2 {
    font-size: 24px;
  }
}

._selectedProperty {
  box-shadow: 0 0 0 2px #00a040;
}

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.react-tel-input .form-control {
  padding-left: 48px !important;
}

.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.dob_field {
  width: 225px;
  padding: 10px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  height: 45px;
}

.gender_select {
  width: 225px;
  padding: 10px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  height: 45px;
  background: white;
}

.spinner-padding {
  padding: 30rem 0;
  text-align: center;
}
