.detail-header {
  margin-top: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  min-height: 80px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 9;
  @media screen and (max-width: 575.98px) {
    margin-top: 91px;
    padding-top: 5px;
  }
}

.preview-header {
  background: rgba(0, 0, 0, 0.5);
  min-height: 80px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  z-index: 9;
  @media screen and (max-width: 575.98px) {
    margin-top: 91px;
    padding-top: 5px;
  }
}

.det-title-wrap {
  display: flex;
  align-items: center;
}

.det-title-wrap h1 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
  line-height: normal;
}

.det-title-wrap small {
  font-weight: 400;
  font-size: 14px;
  color: #c5c5c5;
}

.det-title-wrap a {
  display: inline-flex;
  width: 33px;
  height: 25px;
  padding-right: 15px;
}

.det-title-wrap h1 span {
  background: #282828;
  border-radius: 4px;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #dedede;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.serch-form {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-control-search {
  position: relative;
  max-width: 416px;
  width: 100%;
  margin-right: 25px;
  @media screen and (max-width: 575.98px) {
    margin-right: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    margin-bottom: 13px;
  }
  input {
    background: #f6f6f6;
    border: 1px solid #dcdcdc;
    border-radius: 30px;
    width: 100%;
    padding: 0 21px;
    min-height: 44px;
    // background: rgba(244, 244, 244, 0.2) !important;
    // border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    // color: #fff;
    @media screen and (max-width: 575.98px) {
      padding-right: 45px;
    }
  }

  button {
    border: 0;
    background: none;
    position: absolute;

    right: 15px;
    top: 5px;
  }
}

.form-control-sr {
  position: relative;
  max-width: 416px;
  width: 100%;
  margin-right: 25px;
}

.form-control-sr input:focus {
  outline: 0;
}

.form-control-sr button {
  border: 0;
  background: none;
  position: absolute;

  right: 15px;
  top: 5px;
}

.form-control-sr input {
  background: #f6f6f6;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  width: 100%;
  padding: 0 21px;
  min-height: 44px;
  background: rgba(244, 244, 244, 0.2) !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  color: #fff;
}

.form-control-sr input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  font-weight: 500;
  opacity: 1;
  /* Firefox */
}

.form-control-sr input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  font-weight: 500;
}

.form-control-sr input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  font-weight: 500;
}

.search-form {
  justify-content: flex-end;
  display: flex;
  .form-control-sr {
    @media screen and (max-width: 767.98px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
      margin-top: 4px;
    }
  }
}

.search-form .form-control-sr {
  margin-right: 0;
}

.carousel-indicators {
  display: none;
}

.car-desc {
  position: absolute;
  bottom: 40px;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  width: 100%;
  height: auto;
  font-family: var(--bs-body-font-family);
  @media screen and (max-width: 1199.98px) {
    padding: 0 25px;
  }
}

span.d-name {
  z-index: 9;
  top: 14px;
  right: 14px;
  background: #15bb58;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  padding: 2px 9px;
  line-height: normal;
}

span.post-dte {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  top: 14px;
  right: 14px;

  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  padding: 2px 9px;
  line-height: normal;
}

.sp-dc {
  display: flex;
  justify-content: flex-end;
}

section.det-prop-ow {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  min-height: 90px;
  display: flex;
  align-items: center;
}

.b-dets .ownr-name {
  float: left;
  width: 60%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    width: auto;
  }
}

.b-dets .sub-status small,
.b-dets span.dial small,
.b-dets .ownr-name small {
  font-size: 16px;
  color: #444444;
  display: block;
  margin-bottom: 0px;
}

.sub-status p {
  font-weight: 600;
  font-size: 20px;
  color: #282828;
  position: relative;
  left: 0px;
  top: 0px;
}

.pie-red,
span.dial {
  position: relative;
  width: 100%;
  float: left;
}

.pie-label {
  width: 170px;
  position: absolute;
  top: 0;
  font-weight: 500;
  color: #e8505b;
  text-align: center;
  font-size: 16px;
  line-height: normal;
}

span.dial p {
  margin-bottom: 0;
  color: #444444;
  font-weight: 600;
  font-size: 14px;
}

span.dial {
  margin-bottom: 14px;
}

.b-dets .own-stat {
  display: inline-flex;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background: #ba3838;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  margin-right: 7px;
  font-size: 18px;
}

.b-dets span.ow-nme {
  font-weight: 600;
  color: #444444;
  font-size: 20px;
}

.b-dets {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 767.98px) {
    justify-content: space-between;
  }
}

.b-coin {
  display: flex;
  align-items: center;
}

.b-dets .sub-status {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-bottom: 0;
  @media screen and (max-width: 767.98px) {
    width: auto;
  }
}

.b-coin {
  font-weight: 800;
  font-size: 24px;
  color: #008937;
  @media screen and (max-width: 767.98px) {
    padding-top: 6px;
    padding-bottom: 4px;
  }
}

.b-coin span {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin-left: 5px;
}

.b-coin img {
  max-width: 30px;
  flex: none;
  margin-right: 4px;
  max-width: 24px;
}

section.details-of-property {
  padding-top: 42px;
  padding-bottom: 42px;
  .det-left {
    @media screen and (max-width: 991.98px) {
      order: 2;
    }
  }
  .det-right {
    @media screen and (max-width: 991.98px) {
      order: 1;
      margin-bottom: 30px;
    }
  }
}

h2 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 20px;
  color: #282828;
  display: block;
  line-height: normal;
  margin-bottom: 15px;
}

.am-det-in {
  float: left;
  width: 100%;
  background: #fbfcff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 14px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

span.amnty-title {
  font-weight: 400;
  font-size: 14px;
  color: var(--bs-66);
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @media screen and (max-width: 575.98px) {
    margin-bottom: 2px;
  }
}

span.amnty-title img {
  margin-right: 4px;
}

span.amnty-desc {
  font-weight: 700;
  font-size: 20px;
  color: var(--bs-primary-c);
  @media screen and (max-width: 575.98px) {
    font-size: 16px;
  }
}

.am-det-w {
  margin-bottom: 15px;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  background: rgba(0, 0, 0, 53%);
}

.ab-proprety p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--bs-66);
}

.ab-amnty {
  background: #eef8f8;
  border-radius: 20px;
  float: left;
  width: 100%;
  padding: 40px;
}

.ab-amnty table td {
  padding: 6px 0;
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-primary-c);
}

.ab-proprety {
  float: left;
  width: 100%;
  clear: both;
}

.aut-img {
  width: 40px;
  height: 40px;
  background: #e91e63;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  vertical-align: text-top;
}

.au-detp {
  display: inline-flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 16px;
  color: #282828;
}

.au-detp span.cntr {
  font-size: 14px;
  color: var(--bs-66);
}

.dte-invst {
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-44);
}

.ex-proprety td {
  padding: 5px 0;
}

.aut-img {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(195, 200, 212);

  overflow: hidden;
  width: 40px;
  height: 40px;
  background: #e91e63;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  vertical-align: text-top;
  position: relative;
}

.aut-img img {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px 0px 0px 50%;
}

.ab-invest {
  float: left;
  width: 100%;
}

.ab-invest h2 span {
  color: #029f8c;
  font-size: 20px;
  font-family: var(--bs-body-font-family);
  font-weight: 500;
}

.ab-invest ul {
  margin: 0;
}

.ab-invest ul li {
  font-size: 16px;
  color: var(--bs-66);
  line-height: 28px;
}

.ab-invest img {
  max-width: 100%;
}

.ab-invest table td {
  padding: 5px 0;
}

.ab-invest table td {
  padding: 7px 0;
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-primary-c);
}

.ab-invest table td small {
  color: var(--bs-66);
  font-size: 14px;
  display: block;
}

.ow-adrs table tr td:first-child {
  font-size: 16px;
  color: var(--bs-66);
}

.ow-adrs table tr td:last-child {
  font-size: 16px;
  color: var(--bs-primary-c);
  font-weight: 600;
}

.ow-adrs table td {
  .def-btn {
    margin-left: 0;
  }
}

.det-right {
  padding-left: 5%;
  @media (max-width: 1199.98px) {
    padding-left: 10px;
  }
}

.recent-list {
  padding-top: 50px;
  padding-bottom: 50px;
  .swiper-button-next,
  .swiper-button-prev {
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 20px;
    border-radius: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.14);
    &:after {
      font-size: 20px;
      color: #292727;
    }
  }
}

.swiper-wrapper {
  padding-bottom: 30px;
  padding-top: 30px;
}

.bg-or-stat {
  background: #ff725e;
  border-radius: 6px;
  color: #fff;
  padding: 4px 8px;
  font-size: 18px;
}

.ex-proprety {
  float: left;
  width: 100%;
}

.ex-legal table td:last-child {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.ex-legal table td:last-child a {
  color: #009e3f;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  display: flex;
}

.ex-legal table td:last-child a img {
  margin-left: 7px;
  max-width: 100%;
}

.ex-legal table td:first-child img {
  margin-right: 10px;
  max-width: 100%;
}
.d-main-buttons {
  display: flex;
  flex-wrap: wrap;
  .d-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 84%;
    justify-content: space-between;
    @media screen and (max-width: 1199.98px) {
      width: 100%;
    }
    a {
      text-decoration: none;
    }
  }
}

div#map {
  width: 100% !important;
}

.ab-invest.ow-adrs {
  table {
    @media screen and (max-width: 575.98px) {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1100px) {
  .stickyArea {
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
  }
}

.text-gold {
  color: #de8722;
}
